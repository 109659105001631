import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AppConfigurationService } from './services/app-configuration.service';

export function AppConfigurationFactory(appLoadService: AppConfigurationService) {
  return () => appLoadService.init();
}

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class ConfigurationModule {
  static forRoot(): ModuleWithProviders<ConfigurationModule> {
    return {
      ngModule: ConfigurationModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: AppConfigurationFactory,
          deps: [AppConfigurationService],
          multi: true,
        },
      ],
    };
  }
}
