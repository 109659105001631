import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { combineLatest, filter, Observable, startWith, Subscription } from 'rxjs';
import { SpinnerService } from './modules/commons/spinners';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  displaySpinner$: Observable<boolean>;
  private changePageTitleSub: Subscription;
  constructor(
    spinnerService: SpinnerService,
    private router: Router,
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
  ) {
    this.displaySpinner$ = spinnerService.display$;

    this.changePageTitleSub = combineLatest([
      this.router.events.pipe(
        startWith(null),
        filter(event => event instanceof NavigationEnd),
      ),
      this.translateService.onLangChange.pipe(startWith(null)),
    ]).subscribe(() => {
      const baseTranslationsKey = 'APPLICATION.PAGE_TITLES.';
      const pageTitleFromTranslations: string = this.translateService.instant(baseTranslationsKey + window.location.pathname);

      if (!pageTitleFromTranslations.includes(baseTranslationsKey)) {
        this.titleService.setTitle(pageTitleFromTranslations + ' - Agrosell');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.changePageTitleSub) {
      this.changePageTitleSub.unsubscribe();
    }
  }
}
