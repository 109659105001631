import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ADDRESSES_PATH_SERVICE_TOKEN,
  API_LOGIN_DATA_SERVICE_TOKEN,
  API_URL_SERVICE_TOKEN,
  AuthInterceptorService,
  ORGANIZATION_SERVICE_TOKEN,
  TimeoutInterceptor,
} from '@as/api';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './common/error/global-error-hander';
import { ConfigurationModule } from './modules/commons/configurations/configuration.module';
import { AppConfigurationService } from './modules/commons/configurations/services/app-configuration.service';
import { LayoutModule } from './modules/commons/layouts/layout.module';
import { SpinnerModule, SpinnerService } from './modules/commons/spinners';
import { ApiDataProviderService } from './services/api-data-provider.service';
import { AppRoutesProvider } from './services/app-routing.config';
import { LocaleService } from './services/locale.service';
import { AppTranslationsFactory, TranslationInitializationService } from './translation-initialization.service';
import { TranslationsLoader } from './translations-loader';
import { ShortDatePipe } from './modules/commons/pipes/short-date.pipe';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ConfigurationModule.forRoot(),
    NgbModule,
    LayoutModule.forRoot(AppRoutesProvider),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
        deps: [HttpClient, AppConfigurationService, SpinnerService],
      },
    }),
    ToastrModule.forRoot(),
    SpinnerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppTranslationsFactory,
      deps: [TranslationInitializationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: API_URL_SERVICE_TOKEN,
      useClass: ApiDataProviderService,
    },
    {
      provide: ORGANIZATION_SERVICE_TOKEN,
      useClass: ApiDataProviderService,
    },
    {
      provide: API_LOGIN_DATA_SERVICE_TOKEN,
      useClass: ApiDataProviderService,
    },
    {
      provide: API_LOGIN_DATA_SERVICE_TOKEN,
      useClass: ApiDataProviderService,
    },
    {
      provide: ADDRESSES_PATH_SERVICE_TOKEN,
      useClass: ApiDataProviderService,
    },
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) => localeService.getLocale(),
      deps: [LocaleService],
    },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    ShortDatePipe,
  ],
})
export class AppModule {}
