import { ErrorHandler, Inject, Injectable, Injector, NgZone } from '@angular/core';
import { NotificationService } from '../../modules/commons/services/notification.service';
import { ErrorMessageService } from '../../modules/commons/services/error-message.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private errorMessageService: ErrorMessageService,
    @Inject(Injector) private injector: Injector,
  ) {}

  private get notificationService(): NotificationService {
    return this.injector.get(NotificationService);
  }

  handleError(error: Error) {
    const errorMessage: string = this.errorMessageService.getTranslatedErrorMessage(error);
    this.zone.run(() => this.notificationService.error(errorMessage));

    console.error(error);
  }
}
