import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AppConfigurationService } from './modules/commons/configurations/services/app-configuration.service';
import { SpinnerService } from './modules/commons/spinners';

export interface ITranslations {
  [key: string]: unknown;
}
@Injectable({
  providedIn: 'root',
})
export class TranslationsLoader implements TranslateLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly config: AppConfigurationService,
    private readonly spinnerService: SpinnerService,
  ) {}

  public getTranslation(lang: string): Observable<ITranslations> {
    this.spinnerService.display();
    const translations$ = new TranslateHttpLoader(this.http, './assets/i18n/', '.json').getTranslation(lang);
    const apiUrl = `${this.config.configuration.apiUrl}/api/translations/v1/dictionaries/${lang}`;
    const apiTranslations$ = this.http.get<ITranslations>(apiUrl);
    return forkJoin([translations$, apiTranslations$]).pipe(
      map(([responseTranslations, responseApi]) => {
        const updatedTranslations: ITranslations = { ...responseTranslations };

        if (updatedTranslations['APPLICATION'] && responseApi['APPLICATION']) {
          updatedTranslations['APPLICATION'] = {
            ...updatedTranslations['APPLICATION'],
            ...responseApi['APPLICATION'],
          };
        }

        if (responseApi['API']) {
          updatedTranslations['API'] = {
            ...responseApi['API'],
          };
        }

        return updatedTranslations;
      }),
      finalize(() => this.spinnerService.hide()),
    );
  }
}
