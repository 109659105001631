import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SupportedLanguages } from '@as/api';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { AppConfigurationService } from './modules/commons/configurations/services/app-configuration.service';
import { ITranslations, TranslationsLoader } from './translations-loader';

export function AppTranslationsFactory(translationInitService: TranslationInitializationService) {
  return (): Promise<unknown> => {
    return translationInitService.initializeTranslations();
  };
}

@Injectable({
  providedIn: 'root',
})
export class TranslationInitializationService {
  constructor(
    private translate: TranslateService,
    private appConfigurationService: AppConfigurationService,
    private translationsLoader: TranslationsLoader,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  initializeTranslations(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const checkConfigLoaded = () => {
        if (this.appConfigurationService.configuration.apiUrl) {
          const lang = localStorage.getItem('language') || SupportedLanguages.PL;
          firstValueFrom(this.translationsLoader.getTranslation(lang))
            .then((translations: ITranslations) => {
              this.translate.setTranslation(lang, translations);
              this.translate.use(lang);
              this.hideGlobalLoader();
              resolve(true);
            })
            .catch(error => {
              this.hideGlobalLoader();
              reject(error);
            });
        } else {
          setTimeout(checkConfigLoaded, 100);
        }
      };
      checkConfigLoaded();
    });
  }

  private hideGlobalLoader() {
    const loader = this.document.getElementById('global-spinner');
    if (loader) {
      loader.style.display = 'none';
    }
  }
}
