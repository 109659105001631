import { CanActivateChildFn, Router, UrlTree } from '@angular/router';

import { inject } from '@angular/core';
import { AuthService } from '../auth';
import { Observable } from 'rxjs';
import { RoutePermissionsDto } from '../auth/route-permissions.dto';

export const LoggedUser: CanActivateChildFn = (
  route,
  routerState,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authService = inject(AuthService);
  if (!authService.isLoggedIn()) {
    return inject(Router).navigate(['auth/login']);
  }

  if (isRoutePermissionsDto(route.data)) {
    const allUserPermissions = authService.getUserProfile()?.permissions;
    if (!allUserPermissions || allUserPermissions.length === 0) {
      console.error('No user permission.');
      return false;
    }

    const result = checkPermission(route.data, allUserPermissions);
    if (!result) {
      console.error(
        `Unable to navigate to ${routerState.url} due to missing ${route.data.componentKey}:${route.data.permissions} permission.`,
      );
      return false;
    }
  }

  return true;
};

const checkPermission = (data: RoutePermissionsDto, allUserPermissions: string[]): boolean => {
  if (!data.permissions || data.permissions.length === 0) {
    return allUserPermissions.some(x => x.startsWith(data.componentKey));
  }

  for (const permission of data.permissions) {
    const fullPermission = `${data.componentKey}.${permission}`;
    const result = allUserPermissions.some(x => x === fullPermission);
    if (result) {
      return true;
    }
  }

  return false;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const isRoutePermissionsDto = (data: any): data is RoutePermissionsDto => {
  return typeof data.componentKey === 'string';
};

export const AnonymousUser: CanActivateChildFn = (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  if (!inject(AuthService).isLoggedIn()) {
    return true;
  }

  return inject(Router).navigate(['']);
};
